import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
  accordion: {
    marginBottom: "1rem",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      boxShadow: "1px 1px 3px #888888",
    },
  },
  spacing: {
    marginBottom: "6px",
  },
  underline: {
    textDecoration: "underline",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();
  let i = 0;
  return (
    <div className={classes.root}>
      {list.map((data, index) => (
        <Accordion key={index} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Array.isArray(data.text) ? (
              <Typography component="div" variant="body2">
                <ul>
                  {data.text.map((item, i) => (
                    <li key={i} className={classes.spacing}>
                      {item.includes("Anmeldedaten überprüfen:") ? (
                        <span>
                          <span className={classes.underline}>
                            Anmeldedaten überprüfen:
                          </span>{" "}
                          Um Zugangsprobleme zu vermeiden, prüfen Sie bitte Ihr
                          verwendetes Passwort und Ihre Emailadresse.
                        </span>
                      ) : item.includes("Cache löschen:") ? (
                        <span>
                          <span className={classes.underline}>
                            Cache löschen:
                          </span>{" "}
                          Bitte löschen Sie den Cache des Browsers und teilen
                          Sie uns mit, welchen Browser Sie nutzen.
                        </span>
                      ) : item.includes("VPN-Nutzung:") ? (
                        <span>
                          <span className={classes.underline}>
                            VPN-Nutzung:
                          </span>{" "}
                          Die Nutzung eines VPN-Dienstes kann Auswirkungen auf
                          die Verbindung haben. Wenn Sie einen VPN-Dienst
                          nutzen, teilen Sie uns das bitte mit.
                        </span>
                      ) : item.includes("Fehlerbeschreibung:") ? (
                        <span>
                          <span className={classes.underline}>
                            Fehlerbeschreibung:
                          </span>{" "}
                          Bitte beschreiben Sie den Fehler so detailliert wie
                          möglich. Dazu beschreiben Sie am besten mit einfachen
                          Worten und wenn möglich mit Hilfe von Screenshots.
                        </span>
                      ) : (
                        item
                      )}
                    </li>
                  ))}
                </ul>
              </Typography>
            ) : data.title ===
              "Was passiert mit den von mir übermittelten Daten?" ? (
              <Typography variant="body2" component="span">
                Ihre Daten sind bei uns sicher und werden lediglich für die
                Identifizierung und Übermittlung an das Umweltbundesamt
                benötigt. Mehr Informationen zum Datenschutz finden Sie hier:{" "}
                <Link
                  href="https://www.thgquoten.com/datenschutz"
                  target="_blank"
                  rel="noopener"
                >
                  https://www.thgquoten.com/datenschutz
                </Link>
              </Typography>
            ) : (
              <Typography variant="body2">{data.text}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

const list = [
  {
    title:
      "Wer kann einen Erlös erhalten und somit von den Vorteilen des THG-Quotenservice profitieren?",
    text: "Um von dem THG-Quoten-Mechanismus profitieren zu können, müssen Sie Halter*in eines zulassungspflichtigen und vollelektrischen Fahrzeugs sein. Sowohl E-Transporter und andere E-Nutzfahrzeuge sowie E-PKW, E-Busse als auch E-Motorräder erhalten eine THG-Prämie. Die Höhe der Erlöse hängt von der Fahrzeugklasse ab. Dabei ist es egal wie viele Kilometer mit dem Fahrzeug im Jahr zurückgelegt werden oder wie das Fahrzeug geladen wird. Unser Service ist sowohl für Privat- als auch Geschäftskunden verfügbar.",
  },
  {
    title: "Warum ist der mögliche Erlös im Jahr 2024 gesunken?",
    text: 'Der Marktpreis für THG-Quoten in 2024 ist zuletzt stark gefallen. Aktuell befinden sich die Marktpreise der THG-Quoten auf einem Tiefstand. Ein Hauptgrund hierfür ist unter anderem, dass die Treibhausgas-Einsparungen bzw. THG-Quoten aus der Elektromobilität in "Konkurrenz" zu alternativen Möglichkeiten zur THG-Minderung stehen. Einige dieser alternativen Erfüllungsoptionen sind momentan im Überangebot verfügbar, was den Preis für THG-Quoten weiterhin niedrig hält. Unser Expertenteam geht davon aus, dass die THG-Quote und dazugehörige Prämien in naher Zukunft nicht stark steigen werden.',
  },
  {
    title: "Wie oft kann ich einen Erlös erhalten?",
    text: "Grundsätzlich kann die THG-Quote für jedes E-Fahrzeug nur einmal pro Jahr verkauft werden.",
  },
  {
    title: "Ist der ausgezahlte Erlös steuerfrei?",
    text: "Ja, der Erlös aus dem Verkauf der THG-Quote ist keiner Einkunftsart zuzuordnen. Erhaltene Zahlungen sind daher „privat“ und unterliegen nicht der Einkommensteuer.",
  },
  {
    title:
      "Welche Fahrzeuge können vom THG-Quotenservice der M3E GmbH profitieren und benötige ich eine Wallbox oder Ladesäule?",
    text: [
      "• E-Auto (Fahrzeugklasse M1)",
      "• E-Nutzfahrzeug (Fahrzeugklasse N1)",
      "• E-Bus (Fahrzeugklasse M3)",
      "• E-LKW (Fahrzeugklasse N2 und N3)",
      "• E-Motorrad und sonstige zulassungspflichtige E-Fahrzeuge",
      "Sie brauchen keine Wallbox oder Ladesäule. E-Fahrzeughaltern wird jährlich ein pauschaler Betrag für den Ladestrom zugeschrieben. Dieser Wert liegt momentan beispielsweise bei 2.000 kWh für E-Autos (Klasse M1), 3.000 kWh für leichte E-Nutzfahrzeuge (Klasse N1) und 72.000 kWh für E-Busse (Klasse M3). Daher brauchen wir keinen Nachweis darüber, ob Sie eine Ladeinfrastruktur haben oder nicht.",
    ],
  },
  {
    title: "Bekomme ich einen Erlös für meine Ladeinfrastruktur?",
    text: "Wir arbeiten aktuell daran, Ihnen ebenfalls die Möglichkeit zu bieten, einen Erlös für die THG-Quote Ihrer öffentlichen Ladeinfrastruktur zu erhalten.",
  },
  {
    title:
      "Welche Vorteile bietet mir der THG-Quotenservice der M3E GmbH? Und kostet mich das etwas?",
    text: "Unser Service kostet Sie nichts! Nachdem Sie Ihr Fahrzeug und die notwendigen Dokumente erfolgreich in Ihrem Kundenkonto hochgeladen haben, kümmern wir uns um den Rest. Sie brauchen nichts weiter tun. Sobald Ihr Fahrzeug genehmigt wurde, erhalten Sie Ihren Erlös in Form einer Gutschrift. Unsere automatisierteren Prozesse ermöglichen Ihnen einen reibungslosen Ablauf. Und sollten Sie dennoch mal eine Rückfrage haben, erreichen Sie unser Team schnell und direkt via Telefon oder Kontaktformular.",
  },
  {
    title: "Welche Informationen und Dokumente benötigen Sie von mir?",
    text: "Wir brauchen von Ihnen lediglich Ihre Kontaktdaten und eine aktuelle Kopie Ihrer Zulassungsbescheinigung Teil 1 (Vorderseite) als PDF-Datei. Diese Informationen und das Dokument können Sie ganz einfach in Ihrem Kundenkonto hinterlegen und somit an uns übermitteln.",
  },
  {
    title: "Was passiert mit den von mir übermittelten Daten?",
    text: "Ihre Daten sind bei uns sicher und werden lediglich für die Identifizierung und Übermittlung an das Umweltbundesamt benötigt. Mehr Informationen zum Datenschutz finden Sie hier: <a href='https://www.thgquoten.com/datenschutz' target='_blank'>https://www.thgquoten.com/datenschutz</a>",
  },
  {
    title:
      "Bis wann muss ich mein E-Fahrzeug übermitteln, um einen Erlös zu erhalten?",
    text: "Jeweils bis zum 10. Oktober eines Jahres, haben Sie die Möglichkeit Ihre Daten und E-Fahrzeug über Ihr Kundenkontos an uns zu übermitteln.",
  },
  {
    title: "Wie lange kann es dauern, bis ich meinen Erlös erhalte?",
    text: "Sobald wir die Bescheinigung über die THG-Quote vom Umweltbundesamt erhalten haben, teilen wir Ihnen mit, dass eine Auszahlung Ihres Erlös möglich ist. Wir übermitteln sämtliche Daten und THG-Quoten in regelmäßigen Abständen an das Umweltbundesamt. In der Regel beträgt die Bearbeitungszeit des Umweltbundesamts ca. 10-12 Monate.",
  },
  {
    title:
      "Welchen Grund kann es haben, dass mein E-Fahrzeug abgelehnt und die THG-Quote abgelehnt wurde?",
    text: "Ablehnungsbescheide des Umweltbundesamts sind sehr selten. 99,6 % unserer übermittelten Daten und THG-Quoten werden genehmigt. Der Hauptgrund für Ablehnungen sind jedoch sog. Doppeleinreichungen. Daher sollten Sie beim Kauf eines E-Fahrzeugs immer nachfragen, ob die THG-Quote für das entsprechende Jahr bereits beantragt wurde.",
  },
  {
    title:
      "Muss ich auch nach der Übermittlung meiner Daten, Halter des E-Fahrzeuges sein um einen Erlös zu erhalten?",
    text: "Das Umweltbundesamt vergibt die Pauschale jährlich vollständig an den ersten Antragsteller. Das heißt, bei einem unterjährigen Fahrzeugverkauf hat der neuer Halter keine Berechtigung, wenn Sie als Vorgänger-Halter die THG-Quote bereits beantragt haben.",
  },
  {
    title: "Ich habe Probleme bei der Registrierung oder Anmeldung",
    text: [
      "Melden Sie sich hierzu bitte bei uns unter: 030 403672121 oder thgquoten@m3e-gmbh.com. Um Ihr Anliegen schnellstmöglich zu lösen, bitten wir Sie um Ihre Mithilfe. Bitte prüfen Sie folgende Schritte selbstständig und teilen diese mit uns.",
      "Anmeldedaten überprüfen: Um Zugangsprobleme zu vermeiden, prüfen Sie bitte Ihr verwendetes Passwort und Ihre Emailadresse.",
      "Cache löschen: Bitte löschen Sie den Cache des Browers und teilen Sie uns mit, welchen Browser Sie nutzen.",
      "VPN-Nutzung: Die Nutzung eines VPN-Dienstes kann Auswirkungen auf die Verbindung haben. Wenn Sie einen VPN-Dienst nutze, teilen Sie uns das bitte mit.",
      "Fehlerbeschreibung: Bitte beschreiben Sie den Fehler so detailliert wie möglich. Dazu beschreiben Sie am besten mit einfachen Worten und wenn möglich mit Hilfe von Screenshots.",
    ],
  },
  {
    title:
      "Ich habe ein E-Fahrzeug versehentlich mehrfach in meinem Kundenkonto angelegt.",
    text: "Hierzu schreiben Sie uns bitte eine Nachricht an thgquoten@m3e-gmbh.com. Wir werden Ihr Anliegen umgehend prüfen und das doppelt angelegte E-Fahrzeug entfernen.",
  },
  {
    title: "Ich kann mich nicht mehr in mein bestehendes Kundenkonto anmelden.",
    text: "Nutzen Sie hier unsere Passwort vergessen Funktion: www.thgquoten.com/forgot-password. Geben Sie wie beschrieben Ihre Emailadresse ein, mit der Sie Ihr Kundenkonto registriert haben. Anschließend erhalten Sie eine E-Mail in denen die nächsten Schritte erklärt sind. Folgen Sie ganz einfach den Schritten. Eine Anmeldung mit Ihrer Emailadresse und neuem Passwort sollte wieder problemlos funktionieren.",
  },
];
