import React from "react";
import { makeStyles } from "@material-ui/core";
import Faq from "../components/landingpage/Faq";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const FaqPage = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default FaqPage;
