import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";
import Accordion from "../Accordion";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    padding: "5rem 0rem 2rem 0rem",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const Faq = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          gutterBottom
          align="center"
          className={classes.text}
        >
          FAQ
        </Typography>
        <Accordion />
      </Container>
    </div>
  );
};

export default Faq;
